<script setup lang="ts">
import AbandonedCart from "@/models/AbandonedCart";
import { URL_ACCOUNT_ORDERS_PAGE } from "@/constants/urls";

const onboardingStore = useOnboardingStore();
const menusStore = useMenusStore();
const router = useRouter();
const selectedPlan = ref();
const authStore = useAuthStore();

const currentSelectedPlan = computed(() => onboardingStore.selectedPlan);

function selectPlan(planId: number) {
  selectedPlan.value =
    planId && onboardingStore.plans.find((plan) => plan.id === planId);
  submit();
}

async function submit() {
  if (Number(authStore?.user?.id) > 0) {
    window.location.href = URL_ACCOUNT_ORDERS_PAGE;
    return;
  }

  if (
    currentSelectedPlan.value &&
    currentSelectedPlan.value.id === selectedPlan.value.id
  ) {
    close();
  }
  if (
    currentSelectedPlan.value &&
    currentSelectedPlan.value.threshold > selectedPlan.value.threshold
  ) {
    await downgrade();
  } else {
    await upgrade();
  }

  // Update the url to the new plan
  router.replace({
    query: {
      ...router.currentRoute.value.query,
      plan: selectedPlan.value.slug,
    },
  });

  close();
}
async function downgrade() {
  await onboardingStore.UPDATE_PLAN({
    planId: selectedPlan.value.id,
    sourceUrl: window.location.href,
  });
}
async function upgrade() {
  await onboardingStore.UPDATE_PLAN({
    planId: selectedPlan.value.id,
    sourceUrl: window.location.href,
  });
  if (menusStore.hasPendingCartItems) {
    await abandonedCart.value.addProducts(menusStore.pendingCartItems, window.location.href);
  }
}
function close() {
  menusStore.SET_PENDING_CART_ITEMS([]);
}
const abandonedCart = computed(() => new AbandonedCart(onboardingStore.$state));

watch(
  () => currentSelectedPlan.value,
  (newSelectedPlan) => {
    selectedPlan.value = newSelectedPlan;
  }
);

onMounted(() => {
  selectedPlan.value = currentSelectedPlan.value;
});
</script>
<template>
  <ul class="tw-flex tw-flex-col tw-gap-[12px]">
    <li
      v-for="plan in onboardingStore.plans"
      :key="plan.id"
      class="tw-right-0 tw-flex tw-max-w-[280px] tw-w-full tw-h-[51px] tw-items-center tw-justify-start tw-cursor-pointer tw-bg-[#F2F9F6] tw-rounded-[6px] tw-shadow-[3.71px_3.71px_4.64px_0px_#00000033] tw-outline tw-outline-transparent hover:tw-outline-[#008753]"
      @click="selectPlan(plan.id)"
    >
      <div
        class="tw-text-center tw-w-[51px] tw-h-full tw-font-black tw-font-agenda tw-text-[32px] tw-uppercase tw-leading-3 tw-flex tw-justify-center tw-items-center tw-rounded-tl-[6px] tw-rounded-bl-[6px] tw-bg-[#DAE8E1]"
      >
        {{ plan.threshold }}
      </div>
      <div class="tw-px-[18px] tw-max-w-[145px] tw-w-full tw-font-bold">items per week</div>

      <div
        class="tw-ml-auto tw-pr-[9px] tw-pt-[5px] tw-flex tw-flex-col tw-mb-auto tw-h-full tw-w-full tw-max-w-[69px] tw-items-end"
      >
        <div
          v-if="plan.description"
          class="tw-relative tw-w-[46px] tw-mb-[8px] tw-h-full"
        >
          <svg
            class="tw-absolute tw-z-1"
            width="49"
            height="22"
            viewBox="0 0 49 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M22.2287 1.35628C17.1879 -0.762878 8.99348 -0.762975 3.95251 4.00538C-5.56897 13.0118 4.32651 21.5906 10.4668 20.4288C16.0678 19.3691 17.809 19.6174 26.7094 21.4883C31.7502 22.548 39.0313 22.0182 44.0721 19.3692C53.0734 14.6389 48.6477 1.02945 39.5914 1.88609C33.9906 2.41588 30.1742 4.69659 22.2287 1.35628Z"
              fill="#008753"
            />
          </svg>
          <p
            class="tw-text-[#FFFFFF] tw-text-[12px] tw-z-3 tw-relative tw-z-2 tw-w-full tw-flex tw-justify-center tw-items-center tw-h-full tw-max-h-[20px] tw-font-semibold"
          >
            {{ plan.description }}
          </p>
        </div>

        <div v-if="plan.tag" class="tw-text-[11px] tw-leading-4 tw-font-medium tw-whitespace-nowrap">
          <span>{{ plan.tag }}!</span>
        </div>
      </div>
    </li>
  </ul>
</template>