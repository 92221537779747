export default class MaxPlanThresholdReachedError extends Error {
  errorMessage: string;

  constructor(error: string) {
    super(error);
    this.errorMessage = error;
  }

  dispatchFlashNotification() {
    const event = new CustomEvent("flashMessage", {
      detail: { type: "error", message: this.errorMessage },
      bubbles: true,
    });
    document.dispatchEvent(event);
  }
}
